import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { H1, SideMenu } from "@fastly/consistently"

import Alert from "~components/alert"
import FrontmatterPageContext from "~context/FakePageContext"
import LayoutCore from "./layout-core"
import T from "~components/translator"
import mailto from "~components/mailto"
import { Breadcrumbs, BreadcrumbItem, ComplexHomeText } from "~components/Breadcrumbs/Breadcrumbs"

import { mainArea } from "~vanilla/layout-default.css"
import { main } from "~vanilla/layout-changelog.css"
import { sideMenu } from "~components/SideMenu/changelog.css"

import { LinkDataURL } from "~components/data_url"
import { SideMenuChangelog } from "~components/SideMenu/side-menu-changelog"
import { FavHead } from "~components/Head/FavHead"

const LayoutChangelog = ({ pageContext }) => {
  const { frontmatter, body } = pageContext
  frontmatter["section_safe"] = frontmatter["section"] || "none"

  // for MDXProvider later
  let mdx_components = { T, Alert, mailto }

  return (
    <FrontmatterPageContext.Provider value={frontmatter}>
      <LayoutCore>
        <div className={`${mainArea} grid-main`}>
          <SideMenu
            className={`${sideMenu} main-nav section-${frontmatter.section_safe}`}
            aria-label="Navigation between documents"
            data-swiftype-index="false"
          >
            <SideMenuChangelog />
          </SideMenu>

          <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
            <div className="content-crumb">
              <Breadcrumbs>
                <BreadcrumbItem name={<ComplexHomeText />} url="/" />
                <BreadcrumbItem name="Changes" url="/changes/" />
                {frontmatter["collection"] === "_changelog_significant" ? (
                  <>
                    <BreadcrumbItem name="Significant Changes" url="/changes/significant" />
                    <BreadcrumbItem name="Archive" url="/changes/significant/archive" />
                  </>
                ) : (
                  <BreadcrumbItem name="Archive" url="/changes/archive" />
                )}
              </Breadcrumbs>
            </div>

            <div className="content-title">
              <H1 my="0">{frontmatter.header}</H1>
            </div>

            <div className="content-content">
              <MDXProvider components={mdx_components}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>

              <hr style={{ marginBlock: "2em" }} />

              <p>
                Our <LinkDataURL to="snapshots-archive">documentation archive</LinkDataURL> contains PDF snapshots of
                docs.fastly.com site content as of the above date. Previous updates can be found in the archive as well.
              </p>
            </div>
          </main>
        </div>
      </LayoutCore>
    </FrontmatterPageContext.Provider>
  )
}

export default LayoutChangelog

export const Head = (props) => {
  return (
    <>
      <title>{`${props.pageContext.frontmatter.header} | Fastly Documentation Changes`}</title>
      <FavHead />
    </>
  )
}
