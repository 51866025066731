import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Script } from "gatsby"

import { Container, Box } from "@fastly/consistently"
import { LinkDataURL } from "~components/data_url"

import { changelogMenuItem, changelogMenuItemSub } from "./changelog.css"
import { useLocation } from "@reach/router"

export const SideMenuChangelog = () => {
  const data = useStaticQuery(graphql`
    query RecentChangelogs {
      changelogNormal: allMdx(
        filter: {
          slug: { regex: "/^_changelog//" },
          frontmatter: {
            current: { ne: true },
            published: { ne: false }
            } }
        sort: { order: DESC, fields: slug }
        limit: 4
      ) {
        nodes {
          slug
          frontmatter {
            header
            published
          }
        }
      }
      changelogSig: allMdx(
        filter: {
          slug: { regex: "/^_changelog_significant//" },
          frontmatter: {
            published: { ne: false }
            }
          }
        sort: { order: DESC, fields: slug }
        limit: 4
      ) {
        nodes {
          slug
          frontmatter {
            header
            published
          }
        }
      }
    }
  `)

  const location = useLocation()

  React.useEffect(() => {
    // runs on location, i.e. route, change
    fill_in_form()
  }, [location])

  return (
    <>
      <Script src="//forms.fastly.com/js/forms2/js/forms2.min.js" />

      <Container
        //https://github.com/fastly/Consistently/blob/9ece582189505d8a70153ebda620ae72e7309a1f/src/components/Templates/Container/Container.tsx#L42
        my="0" //because agent
        mr="var(--nav-pad-right) !important"
        ml="var(--nav-pad-left) !important"
        width="calc(100% - var(--nav-pad-left) - var(--nav-pad-right)) !important" // because both the upstream 100% + the margins (vs paddings in the container) make it the right size, but over too far
        >
        <ul style={{paddingLeft: "0", borderRadius: "0.25em", marginBlock: 0 }}>
          <li className={changelogMenuItem} key="current">
            <Link to="/changes/#current_month">Current month</Link>
          </li>

          <li className={changelogMenuItem} key="changes-normal">
            Recent <Link to="/changes/">updates</Link>
            <ul className={changelogMenuItemSub} data-set="changes-normal">
              {makeRecentLogs(data.changelogNormal.nodes, "_changelog/", "/changes/")}
            </ul>
          </li>

          <li className={changelogMenuItem} key="changes-sig">
            Recent <Link to="/changes/significant/">significant</Link> updates
            <ul className={changelogMenuItemSub} data-set="changes-significant">
              {makeRecentLogs(data.changelogSig.nodes, "_changelog_significant/", "/changes/significant/")}
            </ul>
          </li>

          <li className={changelogMenuItem} key="older">
            <Link to="/changes/archive">Older updates</Link>
          </li>

          <li className={changelogMenuItem} key="archive">
            <LinkDataURL to="snapshots-archive">Documentation Archive</LinkDataURL>
          </li>
        </ul>

        <Box
          border="2px solid var(--red)"
          borderRadius="0.25rem"
          p="1em"
          mt="1em"
        >
          <form id="mktoForm_1012"></form>
        </Box>
      </Container>
    </>
  )
}

function makeRecentLogs(dataNodes, slug_collection, link_prefix) {
  return dataNodes.map((node) => {
    const url = node.slug.replace(slug_collection, link_prefix).replace(/(?:\d{4}\/)?(\d+)-(\d+)-(\d+)-(.*)/, "$1/$2/$3/$4")
    const date_part = node.slug.replace(slug_collection, "").replace(/(?:\d{4}\/)?(\d+)-(\d+)-(\d+)-(.*)/, "$1-$2-$3")

    return (
      <li key={date_part}>
        <Link to={url} title={node.frontmatter.header || ""}>
          {date_part}
        </Link>
      </li>
    )
  })
}

function fill_in_form() {
  if (document.getElementById("mktoForm_1012").childElementCount) return
  if (typeof MktoForms2 === "undefined") {
    document.form_timer = setTimeout(fill_in_form, 100)
    return
  }
  document.form_timer = 0
  MktoForms2.loadForm("//forms.fastly.com", "025-XKO-469", 1012)
}
